import Header from './components/Header'
import Hero from './components/Hero'
import Main from './components/Main'

function App() {
  return (
    <>
      <Header />
      <Hero />
      <Main />
    </>
  );
}

export default App;
